import { Route, Switch } from "react-router-dom";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import posthog from "posthog-js";
import { pages } from "./imports";
import Main from "./Main";
import { useEffect } from "react";
import AuthRoute from "./components/AuthRoute";
import QuestionsRoute from "./components/QuestionsRoute";
import StartConsultation from "./components/pages/StartConsultation/StartConsultation";
import ThankYou from "./components/pages/ThankYou/ThankYou";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PharmacyFind from "./components/pages/PharmacyFind/PharmacyFind";
import StripeContainer from "@components/PaymentMethodManager/components/PaymentForm/StripeContainer";
import CheckoutPage from "@components/pages/CheckoutPage";
import Dashboard from "@components/pages/Dashboard/Dashboard";

const App = () => {
  const THEME = createTheme({
    // some elements from material ui can't apply our font.
    typography: {
      fontFamily: "Gustavo",
    },
  });

  const history = useHistory();

  useEffect(() => {
    // Listen for route changes
    const unlisten = history.listen(() => {
      // Capture the pageview with PostHog on every route change
      posthog.capture("$pageview");
    });

    // Cleanup the listener on component unmount
    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <>
      <ThemeProvider theme={THEME}>
        <ToastContainer />
        <Switch>
          {[
            "/cart/:itemsBatch",
            "/dia",
            "/motion",
            "/altitude",
            "/insomnia",
            "/malaria",
            "/nausea",
            "/vaginal-yeast-infection",
            "/urinary-tract-infection",
            "/typhoid",
            "/plus",
          ].map((path, index) => (
            <Route path={path} component={StartConsultation} key={index} />
          ))}
          <Route exact path="/" component={Main} />
          <Route exact path="/login" component={pages.LoginPage} />
          <Route exact path="/security-code/:token" component={pages.SecurityCodePage} />
          <Route exact path="/set-new-password" component={pages.SetNewPasswordPage} />
          <Route exact path="/forgot-password" component={pages.ForgotPasswordPage} />
          <AuthRoute
            path="/dashboard"
            component={() => (
              <StripeContainer>
                <Dashboard />
              </StripeContainer>
            )}
          />
          <AuthRoute
            path="/checkout"
            component={() => (
              <StripeContainer>
                <CheckoutPage />
              </StripeContainer>
            )}
          />
          <Route path="/thank_you" component={ThankYou} />
          <Route path={"/pharmacy-find"} component={PharmacyFind} />
          <Route path="/registration" prevStep={["shipping"]} component={pages.SignupPage} />
          <QuestionsRoute>
            <Route
              path="/start/dia"
              prevStep={["destination_type", "conditions_malarone"]}
              component={pages.TravelersDiarrhea}
            />
            <Route
              path="/start/motion/:consultationName"
              prevStep={["destination_type", "conditions", "conditions_malarone"]}
              component={pages.MotionSickness}
            />
            <Route
              path="/start/altitude"
              prevStep={["destination_type", "conditions", "conditions_malarone"]}
              component={pages.AltitudeSickness}
            />
            <Route
              path="/start/insomnia"
              prevStep={["destination_type", "conditions", "conditions_malarone"]}
              component={pages.Insomnia}
            />
            <Route
              path="/start/infection/:consultationName"
              prevStep={["destination_type", "conditions", "conditions_malarone"]}
              component={pages.Infection}
            />
            <Route
              path="/start/typhoid"
              prevStep={["destination_type", "conditions", "conditions_malarone"]}
              component={pages.ImmunocompromisedPage}
            />
            <Route
              path="/start/plus"
              prevStep={["destination_type"]}
              component={pages.RunwayPlus}
            />

            <Route
              path="/travellinglong"
              prevStep={["triptype"]}
              component={pages.TravellingLong}
            />
            <Route
              path="/welcome-back"
              prevStep={["cart", "dia", "motion", "altitude", "insomnia", "malaria", "plus"]}
              component={pages.WelcomeBack}
            />
            <Route
              path="/state"
              prevStep={["cart", "dia", "motion", "altitude", "insomnia", "malaria", "plus"]}
              component={pages.WhatState}
            />
            <Route path="/date" prevStep={["state"]} component={pages.TakeDate} />
            <Route path="/sync-state" prevStep={["state"]} component={pages.SyncState} />
            <Route
              path="/triptype"
              prevStep={["delivery", "departure"]}
              component={pages.TripType}
            />
            <Route path="/assigned_sex" prevStep={["date"]} component={pages.IdentifyGender} />
            <Route path="/departure" prevStep={["wheretogo"]} component={pages.WhenDepart} />
            <Route path="/delivery" prevStep={["departure"]} component={pages.Delivery} />
            <Route path="/id" prevStep={["marketing-channels"]} component={pages.TakeID} />
            <Route
              path="/confirm-id/:encodedPayload"
              prevStep={["marketing-channels"]}
              component={pages.ConfirmID}
            />
            <Route
              path="/shipping"
              prevStep={["id", "confirm-id"]}
              component={pages.ShippingPage}
            />
            <Route
              path="/pregnancy"
              prevStep={["assigned_sex"]}
              component={pages.CurrentPregnant}
            />
            <Route path="/feeding" prevStep={["pregnancy"]} component={pages.CurrentFeeding} />
            <Route
              path="/conditions"
              prevStep={[
                "destination_type",
                "sleep_troubles",
                "cdiff",
                "mountain_sickness",
                "start",
              ]}
              component={pages.FollowingConditions}
            />
            <Route
              path="/allergies"
              prevStep={["conditions", "conditions_malarone"]}
              component={pages.Allergies}
            />
            <Route
              path="/child_vaccines"
              prevStep={["allergies"]}
              component={pages.RoutineVaccines}
            />
            <Route
              path="/adult_vaccines"
              prevStep={["child_vaccines"]}
              component={pages.Vaccines}
            />
            <Route path="/problems" prevStep={["adult_vaccines"]} component={pages.AnyProblems} />
            <Route path="/medications" prevStep={["problems"]} component={pages.MedicationsTaken} />
            <Route
              path="/parental_consent"
              prevStep={["marketing-channels"]}
              component={pages.ParentalConsent}
            />
            <Route
              path="/marketing-channels"
              prevStep={["problems", "medications"]}
              component={pages.MarketingChannels}
            />
            <Route
              path="/wheretogo"
              prevStep={["feeding", "assigned_sex"]}
              component={pages.WhereToGo}
            />
            <Route path="/addon" prevStep={["wheretogo"]} component={pages.UpsellPage} />
            <Route path="/localpharmacy" component={pages.PharmacyPage} />
            <Route path="/ascent" prevStep={["start"]} component={pages.Ascent} />
            <Route
              path="/activities"
              prevStep={["destination_type", "conditions"]}
              component={pages.Activities}
            />
            <Route
              path="/abx_side_effects"
              prevStep={["activities"]}
              component={pages.SideEffects}
            />
            <Route
              path="/past_medications"
              prevStep={["abx_side_effects"]}
              component={pages.ExperiencedIssues}
            />
            <Route
              path="/medications_plan"
              prevStep={["past_medications"]}
              component={pages.MedicationsPlan}
            />
            <Route
              path="/conditions_malarone"
              prevStep={["medications_plan"]}
              component={pages.ConditionsMalarone}
            />
            <Route
              path="/mountain_sickness"
              prevStep={["ascent"]}
              component={pages.MountainSickness}
            />
            <Route path="/insurance" prevStep={["travellinglong"]} component={pages.Insurance} />
            <Route path="/alone" prevStep={["insurance"]} component={pages.Alone} />
            <Route
              path="/destination_type"
              prevStep={["alone"]}
              component={pages.DestinationType}
            />
            <Route path="/staying_asleep" prevStep={["start"]} component={pages.StayingAsleep} />
            <Route path="/currently_dia" prevStep={["start"]} component={pages.CurrentDia} />
            <Route path="/bowel" prevStep={["currently_dia"]} component={pages.BowelDisease} />
            <Route path="/cdiff" prevStep={["bowel"]} component={pages.CDiff} />
            <Route path="/wakingup" prevStep={["staying_asleep"]} component={pages.WakingUp} />
            <Route path="/sleep_troubles" prevStep={["wakingup"]} component={pages.TimeZone} />
            <Route
              path="/plus_details"
              prevStep={["id", "parental_consent"]}
              component={pages.PlusDetails}
            />
            <Route path="/height_weight" prevStep={["date"]} component={pages.HeightAndWeight} />
            <Route path="/fluconazole" prevStep={["infection"]} component={pages.FluconazolePage} />
            <Route
              path="/immunocompromised"
              prevStep={["infection"]}
              component={pages.ImmunocompromisedPage}
            />
            <Route
              path="/nitrofurantoin"
              prevStep={["infection"]}
              component={pages.NitrofurantoinPage}
            />
          </QuestionsRoute>
        </Switch>
      </ThemeProvider>
    </>
  );
};

export default App;

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";

import useMsgFile from "@hooks/useMsgFile";
import Conversation from "./components/Conversation";
import { setNotification } from "@store/ui/uiSlice";
import { getMessages, getMessagesLastPage, createMessage } from "@store/messages/messagesAction";

import downloadSvg from "../../../../../img/download.svg";
import { AlertBanner, Button, TextField, Card, Link } from "@runwayhealth/runway-components-react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import "./messages.scss";

const MESSAGE_FILE_KEY = "message-file";

const Messages = () => {
  const dispatch = useDispatch();
  const [messageText, setMessageText] = useState("");
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [filePath, setFilePath] = useState("");
  const [base64file, setBase64file] = useState(null);
  const [lockedChat, setLockedChat] = useState(false);
  const user = useSelector((state) => state.user);
  const cases = useSelector((state) => state.case.cases.data);
  const messages = useSelector((state) => state.messages.data);
  const loadingChat = useSelector((state) => state.messages.pending);
  const lastPage = useSelector((state) => state.messages.meta.last_page);
  const currentPage = useSelector((state) => state.messages.meta.current_page);

  useEffect(() => {
    dispatch(setNotification({ message: false }));
    if (user.data["patient-id"]) {
      // Initialize messenger.
      dispatch(getMessagesLastPage());
    }

    // If it is the first consultation.
    if (cases.length === 1) {
      // Then lock chat if the consultation hasn't been purchased.
      if (cases[0].data.invoiceId === null) {
        setLockedChat(true);
      }
    }
  }, []);

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (err) => {
        reject(err);
      };
    });
  };

  const onSelectFile = async (file) => {
    if (!file || file.length === 0) {
      setBase64file(undefined);
      return;
    }
    const base64img = await convertBase64(file);
    setBase64file(base64img);
  };

  const handleInfiniteScrolling = (event) => {
    if (event.target.scrollTop === 0) {
      // Set next page to fetch as the current one.
      let nextPage = currentPage + 1;
      if (currentPage <= lastPage) {
        fetchMessages(nextPage);
      }
    }
  };

  const fetchMessages = (pageNumber) => {
    if (pageNumber > 0 && user.data["patient-id"]) {
      dispatch(
        getMessages({
          page: pageNumber,
        })
      );
    }
  };

  const createMessageFunction = async () => {
    let uploadedFile;
    const isSendingMessage = messages[messages.length - 1]?.isPending;
    if ((messageText && !/^\s*$/.test(messageText)) || (base64file && !isSendingMessage)) {
      if (file) {
        uploadedFile = await useMsgFile(file, MESSAGE_FILE_KEY);
      }
      dispatch(
        createMessage({
          text: messageText,
          uploadedFile,
        })
      );
      setBase64file(null);
      setFile(null);
      setMessageText("");
    }
  };

  const saveFile = (e) => {
    const { files } = e.target;
    const file = files[0];
    setFile(file);
    onSelectFile(file);
    setFilePath(URL.createObjectURL(file));

    if (file.type.includes("image")) {
      setFilePreview(
        <div>
          <img
            className="messages-chats-file"
            src={URL.createObjectURL(file)}
            alt={file.name}
          ></img>
          <p>{file.name}</p>
        </div>
      );
    } else if (file.type.includes("video")) {
      setFilePreview(
        <ReactPlayer controls url={URL.createObjectURL(file)} width="100%" height="100%" />
      );
    } else {
      setFilePreview(<p>📑 {file.name}</p>);
    }
  };

  return (
    <>
      {!lockedChat ? (
        <div className="messages">
          <Card className="messages-chats-main" id="messages-chats-main">
            <Conversation
              messages={messages}
              loadingChat={loadingChat}
              onScroll={handleInfiniteScrolling}
            />
            <div className="meessages-chats-footer">
              <TextField
                as="textarea"
                onChange={(e) => {
                  setMessageText(e.target.value);
                }}
                style={{ height: "auto" }}
                onKeyUp={(e) =>
                  !(e.keyCode === 13 && e.shiftKey) && e.keyCode === 13 && createMessageFunction()
                }
                value={messageText}
                placeholder="Type your message"
              ></TextField>
              <div className="messages-chats-footer-buttons">
                <label className={"download-file-message"}>
                  <img className="imgContainer" src={downloadSvg} alt="" />
                  <input type="file" onChange={saveFile} />
                </label>
                <Button
                  size="sm"
                  iconName="PaperPlaneRight"
                  isIconOnly
                  isLoading={messages[messages.length - 1]?.isPending}
                  onClick={() => createMessageFunction()}
                />
              </div>
            </div>
            {base64file && <div className="messages-chats-file">{filePreview}</div>}
          </Card>
        </div>
      ) : (
        <div className="locked-chat-message">
          <Card>
            <Container>
              <Row>
                <AlertBanner variant="info" isRounded>
                  <AlertBanner.Message>
                    Complete your consultation to chat with your physician.
                  </AlertBanner.Message>
                </AlertBanner>
                <h3>Take control of your consultations!</h3>
                <p>
                  Review pending consultations and complete checkout from your{" "}
                  <Link href="/dashboard">dashboard</Link> to unlock chat support with your
                  physician.
                </p>
              </Row>
            </Container>
          </Card>
        </div>
      )}
    </>
  );
};

export default Messages;

import { PaymentMethod } from "./billing";
import { UserType } from "./mdi";

export const PASSWORD_RESET_TOKEN_KEY = "password-reset-token";

export interface Address {
  address: string;
  address2: string;
  city_name: string;
  zip_code: string;
  state_name: string;
}

export enum Gender {
  Other = 0,
  Male = 1,
  Female = 2,
}

export interface Link {
  id: string;
  url: string;
  token: string;
  visitors: number;
  leads: number;
  conversions: number;
}

export interface Affiliate {
  id: string;
  state: string;
  stripe_customer_id: string;
  visitors: number;
  leads: number;
  conversions: number;
  links: Link[];
}

export interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  gender?: Gender;
  phone_number: string;
  phone_type: number;
  date_of_birth: Date;
  height: number;
  weight: number;
  active: boolean;
  gender_label: string;
  current_medications: string;
  medicalConditions: string;
  allergies: string;
  "patient-id": string | null;
  pregnancy: boolean;
  address: Address; //TODO
  shipping_address: Address;
  password: string;
  confirmPassword: string;
  driver_license_id: string;
  pharmacy_id: string;
  paymentMethods: PaymentMethod[];
  defaultPaymentMethod: string;
  partnerTx?: string;
  isAdmin: boolean;
  affiliateProfile?: Affiliate;
  promocodes: Array<{
    active: boolean;
    code: string;
  }>;
  createdAt?: number; // expressed in milliseconds
}

export interface UserUpdate {
  first_name?: string;
  last_name?: string;
  email?: string;
  date_of_birth?: string;
  gender?: string;
  phone_number?: string;
  phone_type?: string;
  shipping_address?: Address;
  driver_license_id?: string;
}

export interface PasswordUpdate {
  oldPassword: string;
  newPassword: string;
}

export interface Notification {
  event_type: string;
  case_id?: string;
  message?: Message;
}

type Message = {
  id: string;
  user_type: UserType;
  user: {
    first_name: string;
  };
  text: string;
  files: any;
  updated_at: Date;
};

import { FieldNames, PaymentField, PaymentMethodForm } from "@store/../@types/billing";
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from "@stripe/stripe-js";

const useValidatePaymentForm = (form: PaymentMethodForm): PaymentField | boolean => {
  const cardNumberField = validateCardNumber(
    form.cardNumber.value as StripeCardNumberElementChangeEvent
  );
  if (cardNumberField.error.flagged) {
    return {
      ...cardNumberField,
      field: FieldNames.CARD_NUMBER,
    };
  }
  const experyField = validateExpiryDate(form.expiry.value as StripeCardExpiryElementChangeEvent);
  if (experyField.error.flagged) {
    return {
      ...experyField,
      field: FieldNames.EXPIRY,
    };
  }
  const cvcField = validateCvc(form.cvc.value as StripeCardCvcElementChangeEvent);
  if (cvcField.error.flagged) {
    return {
      ...cvcField,
      field: FieldNames.CVC,
    };
  }
  const nameValidation = validateName(form.nameOnCard.value as string);
  if (nameValidation.error.flagged) {
    return {
      ...nameValidation,
      field: FieldNames.NAME_ON_CARD,
    };
  }
  const zipValidation = validateZip(form.zipCode.value as string);
  if (zipValidation.error.flagged) {
    return {
      ...zipValidation,
      field: FieldNames.ZIP_CODE,
    };
  }
  return true;
};

export const validateName = (nameOnCard: string): PaymentField => {
  if (!nameOnCard) {
    // Missing name on card error message.
    return {
      value: nameOnCard,
      error: {
        message: "Missing name on card",
        flagged: true,
      },
    };
  }
  return {
    value: nameOnCard,
    error: {
      flagged: false,
    },
  };
};

export const validateCardNumber = (
  cardNumber: StripeCardNumberElementChangeEvent
): PaymentField => {
  if (cardNumber.empty) {
    return {
      value: cardNumber,
      error: {
        message: "Missing card number",
        flagged: true,
      },
    };
  }

  if (cardNumber.error) {
    // Invalid card number;
    return {
      value: cardNumber,
      error: {
        message: "Invalid card number",
        flagged: true,
      },
    };
  }

  return {
    value: cardNumber,
    error: {
      flagged: false,
    },
  };
};

export const validateExpiryDate = (
  expiryDate: StripeCardExpiryElementChangeEvent
): PaymentField => {
  if (expiryDate.empty) {
    // Missing expiration date.
    return {
      value: expiryDate,
      error: {
        message: "Missing expiration date",
        flagged: true,
      },
    };
  }

  if (expiryDate.error) {
    // Invalid expiration date.
    return {
      value: expiryDate,
      error: {
        message: "Invalid expiration date",
        flagged: true,
      },
    };
  }

  return {
    value: expiryDate,
    error: {
      flagged: false,
    },
  };
};

export const validateCvc = (cvc: StripeCardCvcElementChangeEvent): PaymentField => {
  if (cvc.empty) {
    // Missing cvc.
    return {
      value: cvc,
      error: {
        message: "Missing CVC",
        flagged: true,
      },
    };
  }

  if (cvc.error) {
    // Invalid cvc.
    return {
      value: cvc,
      error: {
        message: "Invalid CVC",
        flagged: true,
      },
    };
  }
  return {
    value: cvc,
    error: {
      flagged: false,
    },
  };
};

export const validateZip = (zipCode: string): PaymentField => {
  if (zipCode.length < 5) {
    // Short code, not valid.
    return {
      value: zipCode,
      error: {
        message: "Invalid Zip Code",
        flagged: true,
      },
    };
  }
  return {
    value: zipCode,
    error: {
      flagged: false,
    },
  };
};

export default useValidatePaymentForm;

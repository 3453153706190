import "./AutocompleteInput.scss";

import { useState } from "react";

import { AddressSuggestion } from "@hooks/useAdressSuggestions";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";

import { StateAvailability } from "../../pages/WhatState/stateAvailability";

type Option = string | AddressSuggestion;

type AutoCompleteProps = {
  key?: string;
  width: string;
  capturedVariant: React.Dispatch<React.SetStateAction<string | AddressSuggestion>>;
  options: Option[];
  onFocus?: () => void;
  onChange?: (value: string) => void;
  onKeyUp?: (value: React.SyntheticEvent) => void;
  defaultValue?: string;
  disabled?: boolean;
  handleBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
  fontSize?: string;
  height?: string;
  placeholder?: string;
  stateAvailability?: StateAvailability[];
  setSyncRequired?: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
};

const AutocompleteInput = ({
  width,
  onFocus,
  onKeyUp,
  handleBlur,
  capturedVariant,
  options,
  defaultValue,
  disabled = false,
  fontSize,
  height,
  placeholder,
  stateAvailability = [],
  setSyncRequired,
  open,
}: AutoCompleteProps) => {
  const [screenWidth, setScreenWidth] = useState(window.screen.width);
  const [selectedOption, setSelectedOption] = useState<AddressSuggestion | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const useStyles = makeStyles({
    root: {
      width: `${width}`,
      fontFamily: "Gustavo",
    },
    inputRoot: {
      fontFamily: "Gustavo",
      paddingBottom: "36px",
      fontSize: screenWidth < 760 ? "20px" : "32px",
      backgroundColor: "red",
    },
  });

  const classes = useStyles();

  let inputStyles = {
    width: `${width}`,
    marginRight: "0.5rem",
    height: height || "5rem",
    border: "none",
    borderBottom: disabled ? "none" : "1px solid #393939",
    outline: "none",
    fontSize: fontSize || screenWidth < 760 ? "20px" : "32px",
    fontFamily: "Gustavo",
  };

  const captureState = (value: AddressSuggestion | string | null) => {
    if (setSyncRequired) {
      setSyncRequired(
        stateAvailability
          .filter((state) => !state.isAsync)
          .some((state: { name: string }) => state.name === value)
      );
    }

    capturedVariant(value ?? "");

    if ((value as AddressSuggestion).street_line) {
      setSelectedOption(value as AddressSuggestion);
      setInputValue((value as AddressSuggestion).street_line);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<{}>, newInputValue: string) => {
    // Only update inputValue if the user is typing manually
    if (selectedOption && newInputValue === selectedOption.full_address) {
      // Avoid overwriting custom value with label
      return;
    }
    setInputValue(newInputValue); // Sync manual input change
  };

  return (
    <Autocomplete
      clearOnBlur={false}
      freeSolo
      open={open}
      value={selectedOption}
      onKeyUp={onKeyUp}
      onBlur={(event: React.FocusEvent<HTMLDivElement>) => {
        // Cast the event target to HTMLInputElement
        const inputElement = event.target as HTMLInputElement;
        captureState(inputElement.value);
      }}
      options={options}
      id={classes.inputRoot}
      inputValue={inputValue}
      onChange={(e, value) => {
        captureState(value);
      }}
      onInputChange={handleInputChange}
      defaultValue={defaultValue}
      getOptionLabel={(option) => (option as AddressSuggestion).full_address ?? option}
      renderInput={(params) => (
        <>
          <div ref={params.InputProps.ref} className="autocomplete-input">
            <input
              value={inputValue}
              autoComplete={"disabled"}
              style={inputStyles}
              {...params.inputProps}
              name="state"
              onFocus={onFocus}
              onBlur={(e) => (handleBlur ? handleBlur(e) : null)}
              placeholder={placeholder || "Select State"}
              disabled={disabled}
            />
          </div>
        </>
      )}
      renderOption={(option: Option) => {
        return (
          <li className="ph-no-capture">{(option as AddressSuggestion).full_address ?? option}</li>
        );
      }}
    />
  );
};

export default AutocompleteInput;

import { useDispatch } from "react-redux";

import { FieldNames, PaymentField, PaymentMethodForm } from "@store/../@types/billing";
import * as PaymentMethodFormSlice from "@store/paymentMethodForm/paymentMethodFormSlice";
import { CardNumberElementComponent } from "@stripe/react-stripe-js";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { PaymentMethodDTO } from "@utils/dtos/PaymentMethodDTO";

import useValidatePaymentForm from "./useValidatePaymentMethod";

const useCreatePaymentMethod = async (
  paymentForm: PaymentMethodForm,
  stripe: Stripe | null,
  elements: StripeElements | null,
  cardComponent: CardNumberElementComponent
) => {
  if (validatePaymentForm(paymentForm)) {
    const card = elements?.getElement(cardComponent) || { token: "" };
    const newPaymentMethodResult = await stripe?.createPaymentMethod({
      type: "card",
      card,
      billing_details: {
        name: paymentForm.nameOnCard.value as string,
        address: {
          postal_code: paymentForm.zipCode.value as string,
        },
      },
    });

    if (newPaymentMethodResult?.paymentMethod) {
      const paymentMethod = new PaymentMethodDTO(newPaymentMethodResult.paymentMethod);
      return paymentMethod;
    }
  }
};

export default useCreatePaymentMethod;

const validatePaymentForm = (paymentMethodForm: PaymentMethodForm): boolean => {
  const validatedForm = useValidatePaymentForm(paymentMethodForm);

  if ((validatedForm as PaymentField)?.error?.flagged) {
    highlightErrorFieldOnSubmit(validatedForm as PaymentField);
    return false;
  }
  return true;
};

const highlightErrorFieldOnSubmit = (invalidField: PaymentField) => {
  const dispatch = useDispatch();
  const invalidFieldName = invalidField.field;
  switch (invalidFieldName) {
    case FieldNames.NAME_ON_CARD:
      dispatch(PaymentMethodFormSlice.updateNameOnCard(invalidField));
      break;
    case FieldNames.CARD_NUMBER:
      dispatch(PaymentMethodFormSlice.updateCardNumber(invalidField));
      break;
    case FieldNames.CVC:
      dispatch(PaymentMethodFormSlice.updateCvc(invalidField));
      break;
    case FieldNames.EXPIRY:
      dispatch(PaymentMethodFormSlice.updateExpiry(invalidField));
      break;
    case FieldNames.ZIP_CODE:
      dispatch(PaymentMethodFormSlice.updateZip(invalidField));
      break;
  }
};
